import { useEffect, useState, useRef } from "react";
import { Oval } from "react-loader-spinner";
import { put } from "../../../../Libraries/Fetch";
import { useSelector } from "react-redux";
import { PasswordInput } from "../../../../Components";
export default ({ showModal, setShowModal, Ref, saveCallback }) => {
  const token = useSelector(state => state.session.token);
  const [loading, setLoading] = useState(false);
  const initialErrorMessage = {
    lama: '',
    baru: '',
    konfirmasi: ''
  }
  let [errorMessage, setErrorMessage] = useState(initialErrorMessage);
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    let form = new FormData();
    form.append("lama", Ref.lama.current.value);
    form.append("baru", Ref.baru.current.value);
    form.append("konfirmasi", Ref.konfirmasi.current.value);
    let req = await put('Teknisi/Personal/editPassword', form, token);
    if (req.data.status == "success") {
      setLoading(false);
      alert(req.data.message)
      setShowModal(false);
      await saveCallback();
    }
    else if (req.data.status == "error") {
      setLoading(false);
      alert(req.data.message);
      if (req.data.data != undefined) {
        if (req.data.data.lama) {
          setErrorMessage((n) => { return { ...n, lama: req.data.data.lama[0] } });
        }
        if (req.data.data.baru) {
          setErrorMessage((n) => { return { ...n, baru: req.data.data.baru[0] } });
        }
      }

    }
  }
  return (
    <div className={showModal ? "modal active" : "modal"}>
      <div className='modal-container'>
        <div className='modal-content'>
          <button onClick={() => { setShowModal(false) }} className='pos-absolute btn-danger' style={{ width: '25px', height: '32px', textAlign: 'center', top: '-10px', right: '-10px' }}>
            X
          </button>
          <div className="modal-header textcolor-black">
            <h1>EDIT PASSWORD</h1>
          </div>
          <form onSubmit={handleSubmit} className='modal-body'>
            <PasswordInput Ref={Ref.lama} label="Password Lama" placeholder="Password Lama"
              errorMessage={errorMessage.lama} />
            <PasswordInput Ref={Ref.baru} label="Password Baru" placeholder="Password Baru"
              errorMessage={errorMessage.baru} />
            <PasswordInput Ref={Ref.konfirmasi} label="Konfirmasi Password Baru" placeholder="Konfirmasi Password Baru"
              errorMessage={errorMessage.konfirmasi} />
            <div className="w-100 dislay-flex mt2">
              <button disabled={false} className="btn-o-primary align-items-center border-rad-5 display-flex textcolor-black p2 m1">
                {loading && <Oval height={25} width={25} />}
                Simpan
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}