import { useEffect, useState, useRef } from "react";
import { Oval } from "react-loader-spinner";
import { put } from "../../../../Libraries/Fetch";
import { useSelector } from "react-redux";
import { Textinput, ImgUpload, SelectInput } from "../../../../Components";
import { faEnvelope, faMapMarker, faPhone, faUser, faVenusMars } from "@fortawesome/free-solid-svg-icons";
export default ({ showModal, setShowModal, Ref, saveCallback }) => {
  const token = useSelector(state => state.session.token);
  const [loading, setLoading] = useState(false);
  const initialErrorMessage = {
    username: '',
    jekel: '',
    nama:'',
    email:'',
    telp:'',
    alamat:''
  }
  let [errorMessage, setErrorMessage] = useState(initialErrorMessage);
  let [prev, setPrev] = useState("");
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    let form = new FormData();
    form.append("username", Ref.username.current.value);
    form.append("jekel", Ref.jekel.current.value);
    form.append("email", Ref.email.current.value);
    if (Ref.foto.current.files[0]!=undefined){
      form.append("foto", Ref.foto.current.files[0]);
    }
    let req = await put('Teknisi/Personal/editData', form, token);
    if (req.data.status == "success") {
      setLoading(false);
      alert(req.data.message)
      setShowModal(false);
      await saveCallback();
    }
    else if (req.data.status == "error") {
      setLoading(false);
      alert(req.data.message);
      if (req.data.data.username) {
        setErrorMessage((n) => { return { ...n, username: req.data.data.username[0] } });
      }
      if (req.data.data.jekel) {
        setErrorMessage((n) => { return { ...n, jekel: req.data.data.jekel[0] } });
      }
      if (req.data.data.email) {
        setErrorMessage((n) => { return { ...n, email: req.data.data.email[0] } });
      }
    }
  }
  useEffect(() => {
    setPrev(Ref.prevFoto.current);
  }, [showModal])
  return (
    <div className={showModal ? "modal active" : "modal"}>
      <div className='modal-container'>
        <div className='modal-content'>
          <button onClick={() => { setShowModal(false) }} className='pos-absolute btn-danger' style={{ width: '25px', height: '32px', textAlign: 'center', top: '-10px', right: '-10px' }}>
            X
          </button>
          <div className="modal-header textcolor-black">
            <h1>EDIT DATA USER</h1>
          </div>
          <form onSubmit={handleSubmit} className='modal-body'>
            <Textinput Ref={Ref.username} label="username" placeholder="username"
              icon={faUser} errorMessage={errorMessage.username} />
            <SelectInput Ref={Ref.jekel} label="Jenis Kelamin" icon={faVenusMars} errorMessage={errorMessage.jekel}>
              <option value="L">Laki-laki</option>
              <option value="P">Perempuan</option>
            </SelectInput>
            <Textinput Ref={Ref.email} label="Email" placeholder="email"
              icon={faEnvelope} errorMessage={errorMessage.email} />
            <div className="w-100 mt2">
              <ImgUpload
                name="gambar"
                prev={prev}
                setPrev={setPrev}
                Ref={Ref.foto}
                label="Gambar" />
            </div>
            <div className="w-100 dislay-flex mt2">
              <button disabled={false} className="btn-o-primary align-items-center border-rad-5 display-flex textcolor-black p2 m1">
                {loading && <Oval height={25} width={25} />}
                Simpan
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}